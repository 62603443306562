const parteDiarioRoutes = [

{
    path: '/parte-diario/listar',
    name: 'ListarPartesDiarios',
    component: () =>
        import ('@/modules/parte_diario/views/ListarPartesDiarios'),
    meta: { requiresAuth: true, domain: 'ListarPartesDiarios', title: 'Ver partes diarios', valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"] }
},
{
    path: '/parte-diario/calendar',
    name: 'CalendarPartesDiarios',
    component: () =>
        import ('@/modules/parte_diario/views/CalendarioDePartesDiarios'),
    meta: { requiresAuth: true, domain: 'ParteDiario', title: 'Calendario de partes diarios', valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"] }
},
{
    path: '/parte-diario/edit/:date',
    name: 'CreateAndEditParteDiario',
    component: () =>
        import ('@/modules/parte_diario/views/CreateAndEditParteDiario'),
    meta: { requiresAuth: true, domain: 'ParteDiario', title: 'Partes diarios', valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"] }
},
]

export default parteDiarioRoutes
