import Axios from 'axios'

if (window.location.hostname == 'demorrhh.pampadev.com.ar') {
    Axios.defaults.baseURL = "https://demorrhhapi.pampadev.com.ar/api";
} else {
    Axios.defaults.baseURL = "http://localhost:8000/api";
}

const token = localStorage.getItem('token')
if (token) {
    Axios.defaults.headers.common['Authorization'] = token
}

Axios.interceptors.response.use(function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function(error) {
    if (error.response.status == 500) {
        error.status = 500
        return new Promise(function(resolve, reject) {
            throw error;
        });
    }
    return new Promise(function(resolve, reject) {
        throw error;
    });
});
