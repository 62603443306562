const liquidacionesRoutes = [{
        path: '/liquidaciones/listar',
        name: 'ListarLiquidaciones',
        component: () =>
            import ('@/modules/liquidaciones/views/ListarLiquidaciones'),
        meta: { requiresAuth: true, domain: 'Liquidaciones', title: 'Listar liquidaciones', valid: ['RRHH', 'dev'] }
    },
    {
        path: '/liquidaciones/nueva',
        name: 'NuevaLiquidacion',
        component: () =>
            import ('@/modules/liquidaciones/views/NuevaLiquidacion'),
        meta: { requiresAuth: true, domain: 'Liquidaciones', title: 'Nueva liquidación', valid: ['RRHH', 'dev'] }
    },
    {
        path: '/liquidaciones/ver/:id',
        name: 'VerLiquidacion',
        component: () =>
            import ('@/modules/liquidaciones/views/VerLiquidacion'),
        meta: { requiresAuth: true, domain: 'Liquidaciones', title: 'Ver liquidación', valid: ['RRHH', 'dev'] }
    },
    // {
    //     path: '/liquidaciones/editar/:id',
    //     name: 'EditarLiquidacion',
    //     component: () =>
    //         import('@/modules/liquidaciones/views/EditarLiquidacion'),
    //     meta: { requiresAuth: true, domain: 'Liquidaciones' }
    // },
]

export default liquidacionesRoutes