const licenciasRoutes = [{
        path: '/licencias/listar',
        name: 'ListarLicencias',
        component: () =>
            import ('@/modules/licencias/views/ListarLicencias'),
        meta: { requiresAuth: true, domain: 'Licencias', title: 'Listar licencias', valid: ['RRHH', 'dev'] }
    },
    {
        path: '/licencias/ver-por-empleado',
        name: 'VerLicenciasPorEmpleado',
        component: () =>
            import ('@/modules/licencias/views/VerPorEmpleado'),
        meta: { requiresAuth: true, domain: 'Licencias', title: 'Ver licencias por empleado', valid: ['RRHH', 'dev', 'Jefe', 'Gerente'] }
    },
    {
        path: '/licencias/ver-por-tipo',
        name: 'VerLicenciasPorTipo',
        component: () =>
            import ('@/modules/licencias/views/VerPorTipo'),
        meta: { requiresAuth: true, domain: 'Licencias', title: 'Ver licencias por tipo', valid: ['RRHH', 'dev', 'Jefe', 'Gerente'] }
    },
    {
        path: '/licencias/ver-por-gerencia',
        name: 'VerLicenciasPorGerencia',
        component: () =>
            import ('@/modules/licencias/views/VerPorGerencia'),
        meta: { requiresAuth: true, domain: 'Licencias', title: 'Ver licencias por gerencia', valid: ['RRHH', 'dev'] }
    },
    {
        path: '/licencias/ver-por-area',
        name: 'VerLicenciasPorArea',
        component: () =>
            import ('@/modules/licencias/views/VerPorArea'),
        meta: { requiresAuth: true, domain: 'Licencias', title: 'Ver licencias por area', valid: ['RRHH', 'dev', 'Gerente'] }
    }
]

export default licenciasRoutes