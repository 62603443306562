import store from '@/store'
import router from '@/router/index'

router.beforeEach((to, from, next) => {

    let title = document.querySelector("title");
    title.innerHTML = `${to.meta.title} - DEMO RRHH`;
    if (store.state.user) {
        checkAuth(to, next)
    } else {
        store.commit('SET_READY', false);
        store.commit('SET_CHECK', false);
        store.dispatch('attempt', localStorage.getItem('token'))
            .then(() => {
                store.commit('SET_READY', true);
                checkAuth(to, next)
            });
    }
})

function checkAuth(to, next) {
    if (!to.meta.requiresAuth) {
        next()
        return
    }
    if (to.meta.requiresAuth && store.state.user == null) {
        next({ name: 'Login' })
        return
    } else {
        if (!store.state.user.datos_validados && to.name != 'ValidarDatos') {
            next({ name: 'ValidarDatos' })
            return
        } else {
            if (to.name == 'Inicio' || to.name == 'CarteleraDense') {
                next()
                return
            }
            if (to.meta.valid && to.meta.valid.includes(store.state.user.tipo)) {
                next();
                return
            } else {
                next('/')
                return
            }
        }
    }
}
