const obrasSocialesRoutes = [{
        path: '/obras-sociales/listar',
        name: 'ListarObrasSociales',
        component: () =>
            import ('@/modules/obras_sociales/views/ListarObrasSociales'),
        meta: { requiresAuth: true, domain: 'ObrasSociales', title: 'Listar obras sociales', valid: ['RRHH', 'dev'] }
    },
    {
        path: '/obras-sociales/ver/:id',
        name: 'VerObraSocial',
        component: () =>
            import ('@/modules/obras_sociales/views/VerObraSocial'),
        meta: { requiresAuth: true, domain: 'ObrasSociales', title: 'Ver obra social', valid: ['RRHH', 'dev'] }
    }
]

export default obrasSocialesRoutes