var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.label))]),(_vm.prepend || _vm.append)?_c('div',{staticClass:"input-group"},[(_vm.prepend)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('icon',{attrs:{"feather":_vm.prepend}})],1)]):_vm._e(),(_vm.select)?[_c('select',{class:[
          _vm.classInput ? _vm.classInput : 'form-control',
          _vm.required || _vm.numeric ? 'is-invalid' : '' ],attrs:{"id":_vm.id,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.update($event.target.value)}}},[(_vm.options)?[_c('option',{domProps:{"value":null}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.options),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])})]:[_vm._t("options")]],2)]:(_vm.textarea)?[_c('textarea',{class:[
          _vm.classInput ? _vm.classInput : 'form-control',
          _vm.required || _vm.numeric ? 'is-invalid' : '' ],attrs:{"id":_vm.id,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.update($event.target.value)}}})]:[_c('input',{class:[
          _vm.classInput ? _vm.classInput : 'form-control',
          _vm.required || _vm.numeric ? 'is-invalid' : '' ],attrs:{"id":_vm.id,"placeholder":_vm.placeholder,"type":_vm.type ? _vm.type : 'text',"inputmode":_vm.inputmode},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.update($event.target.value)},"keypress":_vm.validate}})],(_vm.append)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_c('icon',{attrs:{"feather":_vm.append}})],1)]):_vm._e()],2):[(_vm.select)?[_c('select',{class:[
          _vm.classInput ? _vm.classInput : 'form-control',
          _vm.required || _vm.numeric ? 'is-invalid' : '' ],attrs:{"id":_vm.id,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.update($event.target.value)}}},[(_vm.options)?[_c('option',{domProps:{"value":null}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.options),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option.text)+" ")])})]:[_vm._t("options")]],2)]:(_vm.textarea)?[_c('textarea',{class:[
          _vm.classInput ? _vm.classInput : 'form-control',
          _vm.required || _vm.numeric ? 'is-invalid' : '' ],attrs:{"id":_vm.id,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.update($event.target.value)}}})]:[_c('input',{class:[
          _vm.classInput ? _vm.classInput : 'form-control',
          _vm.required || _vm.numeric ? 'is-invalid' : '' ],attrs:{"id":_vm.id,"placeholder":_vm.placeholder,"type":_vm.type ? _vm.type : 'text',"inputmode":_vm.inputmode,"disabled":_vm.disabled,"readonly":_vm.readonly,"autocomplete":"off"},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.update($event.target.value)},"keypress":_vm.validate}})]],(_vm.required)?_c('small',{staticClass:"form-message text-danger"},[_vm._v("Éste campo es obligatorio.")]):_vm._e(),(_vm.numeric)?_c('small',{staticClass:"form-message text-danger"},[_vm._v("Sólo se aceptan números.")]):_vm._e(),_vm._t("helper"),_vm._t("validation")],2)}
var staticRenderFns = []

export { render, staticRenderFns }